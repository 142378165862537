//React
import React from 'react'

//Gatsby
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Icons
import { FaArrowRight } from 'react-icons/fa'

const Snippet = (props) => (
  <StaticQuery
    query={graphql`
      query {
        home1: file(
          relativePath: {
            eq: "stock/unsplash/mike-petrucci-c9FQyqIECds-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 700, cropFocus: EAST) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        home2: file(
          relativePath: {
            eq: "stock/unsplash/yogendra-singh-2jMsDzb9t2k-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 700, cropFocus: EAST) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => (
      <div class="bg-yellow-6 py-3">
        <div class="container">
          <div class="row d-flex align-items-center justify-content-center">
            <div class="col-lg-6 m-3 mt-md-0 home-callout-container border-1-bluegreen-1 rounded-corners">
              <Img
                css={{
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  height: 216,
                  borderRadius: 6,
                }}
                fluid={data.home1.childImageSharp.fluid}
              />
              <div class="gradient-bg-home-1"></div>

              <div
                class="d-flex flex-column"
                style={{ position: `relative`, zIndex: 2, height: '100%' }}
              >
                <h3 class="h2 text-left font-weight-bold p-3 display-3 white-1">
                  Features
                </h3>
                <div
                  class="home-callout-text text-left d-flex flex-column flex-grow-1"
                  style={{ width: '80%', marginRight: '20%' }}
                >
                  <p class="lead white-1">
                    Employee &amp; customer wellness self-certifications with
                    intuitive onboarding, alerts, reporting, and more
                  </p>
                  <Link
                    to="/about/features/"
                    className="mt-auto mb-2 btn btn-yellow align-self-start"
                  >
                    See features{' '}
                    <FaArrowRight style={{ marginTop: -1 }} class="btn-arrow" />
                  </Link>
                </div>
              </div>
            </div>

            <div class="col-lg-5 m-3 mt-3 mt-md-0 mb-2 home-callout-container border-1-yellow-1 rounded-corners">
              <Img
                css={{
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  width: '100%',
                  height: 216,
                  borderRadius: 6,
                }}
                fluid={data.home2.childImageSharp.fluid}
              />
              <div class="gradient-bg-home-2"></div>

              <div
                class="d-flex flex-column align-items-end"
                style={{ position: `relative`, zIndex: 2, height: '100%' }}
              >
                <h3 class="h2 text-left font-weight-bold p-3 display-3 white-1">
                  Health screenings by state
                </h3>
                <div
                  class="home-callout-text text-right d-flex flex-column flex-grow-1"
                  style={{ width: '70%', marginLeft: '30%' }}
                >
                  <p class="lead white-1">
                    See your state's requirements on employee symptom and
                    temperature screenings
                  </p>
                  <Link
                    to="/resources/employee-screening-temperature-state-guidance/"
                    // to="/resources/employee-health-screening-tool/"
                    className="mt-auto mb-2 btn btn-primary align-self-end"
                  >
                    View guidelines{' '}
                    <FaArrowRight style={{ marginTop: -1 }} class="btn-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )}
  />
)

export default Snippet
