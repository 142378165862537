//React
import React from 'react'

//Gatsby
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'

//Packages
import Helmet from 'react-helmet'

//Components
import Layout from '../components/layout'
import HomeCallouts from '../components/marketing/homeCallouts'
import FeatureIcon from '../components/marketing/featureIcon'
import PreviewVideo from '../components/elements/previewVideo'

//Icons
import { FaArrowRight } from 'react-icons/fa'

export default class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet
          title="Picohealth - Automated employee mobile health assessments"
          meta={[
            {
              name: 'description',
              content:
                'Picohealth helps reduce risk and drive customer confidence with mobile employee health screenings for employees and customers',
            },
            {
              name: 'keywords',
              content:
                'Covid, Covid-19, health, health questionnaire, covid questions, return to work, pandemic, pandemic assessment questions, worker health question, workplace health questions, workplace health, company health, company safety',
            },
          ]}
        />

        <div class="callout-home d-flex align-items-center justify-content-center">
          <div class="container">
            <div class="row">
              <div class="col-lg-6">
                <h1 class="text-center text-md-left font-weight-bold">
                  Automate employee symptom screenings
                </h1>
                <h2 class="lead text-center text-md-left">
                  Reduce risk and drive customer confidence with mobile health
                  assessments for employees &amp; customers
                </h2>
              </div>
            </div>
          </div>
          <div class="overlay"></div>
          <Img
            css={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: 300,
              zIndex: 10,
            }}
            style={{ position: `absolute` }}
            fluid={this.props.data.homebg.childImageSharp.fluid}
          />
        </div>

        <div class="border-bottom-1-yellow-5 bg-yellow-6 py-2">
          <div class="container my-2 py-4">
            <div class="row">
              <div class="col-lg-8 offset-lg-2">
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <h2 class="font-weight-light text-center display-3 black-4">
                    Reduce risk by screening employees &amp; customers for COVID
                    symptoms before they arrive
                  </h2>
                  <Link to="/about/" class="btn btn-primary btn-cta mt-2">
                    How it works <FaArrowRight class="btn-arrow" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*         
        <div class="bg-yellow-6">
          <div class="container mt-4 py-2">
            <div class="row d-flex">
              <div class="col-lg-12 d-flex flex-column align-items-center justify-content-center">
                <h3 class="text-center display-3">Mindfulness challenges</h3>

                <div class="col-md-6 m-3 mt-md-0 home-callout-container border-1-yellow-1 rounded-corners">
                  <div class="gradient-bg-home-1"></div>

                  <div
                    class="d-flex flex-column"
                    style={{ position: `relative`, zIndex: 2, height: '100%' }}
                  >
                    <h3 class="text-left font-weight-bold p-3 display-3 white-1">
                      Features
                    </h3>
                    <div
                      class="home-callout-text text-left d-flex flex-column flex-grow-1"
                      style={{ width: '60%', marginRight: '40%' }}
                    >
                      <p class="lead white-1">
                        Branded video containers with content screening,
                        analytics &amp; lead gen
                      </p>
                      <Link
                        to="/about/features/"
                        className="mt-auto mb-2 btn btn-yellow align-self-start"
                      >
                        Discover{' '}
                        <FaArrowRight
                          style={{ marginTop: -1 }}
                          class="btn-arrow"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div class="py-4 my-4">
          <div class="container">
            {/* <div class="row p-2 mb-4">
              <div class="col-lg-12">
                <h3 class="text-center font-weight-light display-3">
                  Mindfulness challenges
                </h3>
              </div>
            </div> */}
            <div class="row d-flex pb-4">
              <div class="col-lg-4 d-flex flex-column align-items-center justify-content-center">
                <PreviewVideo
                  videosource="https://picohealthmkt.s3.amazonaws.com/static/web/picohealth-overview-video.mp4"
                  poster="https://picohealthmkt.s3.amazonaws.com/static/web/picohealth-overview-video-poster.jpg"
                />
                {/* <LottieContainer
                  name="rocket"
                  width={200}
                  height={200}
                  marginTop={-40}
                  zIndex={0}
                /> */}
              </div>
              <div class="col-lg-8 d-flex flex-column align-items-center justify-content-center">
                {/* <div class="font-title-callout-sub h5 blue-1">
                  Turnkey program
                </div> */}
                <h2 class="text-center font-weight-bold mt-2 mt-lg-0">
                  Automate employee health checks with text messaging
                </h2>
                <p class="lead text-center display-4">
                  Reduce your work by automating symptom screening via SMS
                  messaging, with no apps to download or install
                </p>

                <Link
                  to="/use-cases/retail/"
                  class="btn btn-primary btn-cta mb-4"
                >
                  Discover use cases <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="gradient-callout-tropical mb-4 pt-2 pb-4">
          <div class="container">
            <div class="row d-flex">
              <div class="col-md-8 offset-md-2 rounded-corners text-center p-2 py-4">
                <div class="d-flex justify-content-center align-items-center mb-3">
                  <FeatureIcon context="thumbsup" />
                </div>
                {/* <div class="font-title-callout-sub h5 white-1">Notify me</div> */}
                <h2 class="white-1 font-weight-bold">
                  Drive customer confidence as you re-open
                </h2>
                <p class="lead white-2 line-height-4">
                  Picohealth is available to automate health symptom screenings
                  of employees and customers for COVID-19
                </p>
                <Link to="/request-demo/" class="btn btn-cta-outline">
                  Request demo <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-3 mb-4">
          <HomeCallouts />
        </div>

        {/* <div class="gradient-callout-warm pt-2 pb-4">
          <div class="container">
            <div class="row d-flex">
              <div class="col-md-12 rounded-corners text-center p-2 py-4">
                <div class="d-flex justify-content-center align-items-center mb-3">
                  <FeatureIcon context="hospital" />
                </div>
                <h2 class="white-1 font-weight-bold">
                  Together, we can all beat Covid-19
                </h2>
                <p class="lead white-2">
                  Let's rally our communities, join together, and rise above
                </p>
                <Link to="/about/covid19-crisis/" class="btn btn-cta-outline">
                  More <FaArrowRight class="btn-arrow" />
                </Link>
              </div>
            </div>
          </div>
        </div> */}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query {
    homebg: file(
      relativePath: { eq: "stock/unsplash/arturo-rey-5yP83RhaFGA-unsplash.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1920, maxHeight: 600, cropFocus: EAST) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
