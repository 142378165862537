import React from 'react'

const Snippet = (props) => (
  <>
    <video
      class="video-preview"
      autoPlay="autoplay"
      muted
      playsInline
      loop={true}
      height={300}
      width={300}
      // poster={props && props.poster ? props.poster : ''}
    >
      <source src={props.videosource} />
    </video>
  </>
)

export default Snippet
